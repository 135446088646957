<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        autocomplete="off"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              name="Vize Fiyat"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Vize Fiyat">
                <b-input-group>
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="baslik"
                    v-model="form.vize_fiyat"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <b-input-group-prepend>
                    <v-select
                      v-model="form.vize_kur"
                      :options="kurlar"
                      :reduce="(uye) => uye.banka_kodu"
                      value="banka_kodu"
                      :clearable="false"
                      class="invoice-filter-select select-size-lg d-block"
                      style="width: 150px"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.banka_kodu }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.banka_kodu }}
                      </template>
                    </v-select>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              name="Defter Bedeli Kart Fiyat"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Defter Bedeli Kart Fiyat">
                <b-input-group>
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="baslik"
                    v-model="form.defter_kart_fiyat"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <b-input-group-prepend>
                    <v-select
                      v-model="form.defter_kart_kur"
                      :options="kurlar"
                      :reduce="(uye) => uye.banka_kodu"
                      value="banka_kodu"
                      :clearable="false"
                      class="invoice-filter-select select-size-lg d-block"
                      style="width: 150px"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.banka_kodu }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.banka_kodu }}
                      </template>
                    </v-select>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              name="Ceza Çarpan"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Ceza Çarpan">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="baslik"
                  v-model="form.ceza_carpan"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Mail Gönderimi">
              <v-select
                v-model="form.mail_gonder"
                :options="mailTurleri"
                :reduce="(mail) => mail.value"
                label="title"
                :clearable="false"
                class="invoice-filter-select select-size-lg d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <div class="d-flex">
              <b-button
                squared
                block
                type="submit"
                size="lg"
                variant="primary"
                class="mr-2"
              >
                <i class="fad fa-save pr-2"></i>
                <span class="align-middle">{{
                  form.k_no == null ? "EKLE" : "GÜNCELLE"
                }}</span>
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import store from "@/store";
import vSelect from "vue-select";
import { ref, computed, defineComponent } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
export default defineComponent({
  components: { vSelect },
  setup(_, context) {
    const expo = {};

    const toast = useToast();

    expo.kurlar = computed(() => store.getters.getKurTurleri);

    expo.mailTurleri = ref([
      {
        value: true,
        title: "Evet İşlem Sonunda Bize Bildir.",
      },
      {
        value: false,
        title: "Hayır Gerek Yok.",
      },
    ]);

    expo.form = ref({
      vize_fiyat: 0,
      vize_kur: "TRY",
      defter_kart_fiyat: 0,
      defter_kart_kur: "TRY",
      ceza_carpan: 2,
      mail_gonder: false,
    });

    const handlerFetchAllData = async () => {
      context.emit("show", true);
      await store.dispatch("kurTurleriListele");
      await store.dispatch("harcAyarGetir").then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit("show", false);
    };

    handlerFetchAllData();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("harcAyarEkle", expo.form.value)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            toast.success(
              !expo.form.value.k_no ? "Ekleme Başarılı" : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );

            context.emit("show", false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
