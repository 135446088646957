<template>
  <b-sidebar
    backdrop
    shadow
    :visible="openModal"
    right
    width="50%"
    @change="(val) => $emit('closeModal', val)"
  >
    <template #header="{ hide }">
      <div class="sidebar-sepet-header">
        <h6 class="mb-0 font-weight-bold">
          <i class="fad fa-plus"></i> Fiyat Tanımla
        </h6>
        <i class="fad fa-times-square" @click="hide" />
      </div>
    </template>
    <template #default>
      <b-overlay :show="show" rounded="sm">
        <div class="sidebar-sepet-items px-2">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
              autocomplete="off"
            >
              <b-row>
                <b-col cols="12" md="6">
                  <validation-provider
                    name="Başlık"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Başlık">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        ref="baslik"
                        v-model="form.baslik"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider
                    name="Gün"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Gün">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        type="number"
                        v-model="form.gun"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <div
                    class="d-flex justify-content-between align-items-center py-3"
                  >
                    <label for="statu" class="h6 mt-1">Form Gösterim</label>
                    <b-form-checkbox
                      :checked="form.gosterim"
                      v-model="form.gosterim"
                      switch
                      size="lg"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <td>ÜLKE KODU</td>
                        <td>FİYAT</td>
                        <td>KUR</td>
                      </tr>
                    </thead>
                    <tbody>
                      <Items
                        v-for="(item, index) in form.grup_fiyat"
                        :key="index"
                        :item="item"
                        :kurlar="kurlar"
                      />
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    size="lg"
                    squared
                    variant="success"
                    block
                    type="submit"
                    v-if="!form.k_no"
                  >
                    <i class="fad fa-plus" /> TANIMLA
                  </b-button>
                  <b-button
                    v-else
                    size="lg"
                    squared
                    variant="warning"
                    block
                    type="submit"
                  >
                    <i class="fad fa-edit" /> GÜNCELLE
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import store from "@/store";
import {
  ref,
  computed,
  defineComponent,
  toRefs,
  watch,
  onMounted,
} from "@vue/composition-api";

import { useToast } from "vue-toastification/composition";
import Items from "./Items.vue";
export default defineComponent({
  components: { Items },
  props: {
    show: [Boolean],
    openModal: [Boolean],
    updateData: { type: Object, required: true },
    kurlar: { type: Array, required: true },
  },
  setup(props, context) {
    const expo = {};

    const toast = useToast();

    const { updateData } = toRefs(props);

    expo.gruplar = computed(() => store.getters.getHarcGrup);

    expo.form = ref({
      baslik: null,
      gun: 1,
      gosterim: false,
      grup_fiyat: [],
    });

    expo.resetForm = () => {
      expo.form.value = {
        baslik: null,
        gun: 1,
        gosterim: false,
        grup_fiyat: [],
      };
    };

    onMounted(() => {
      expo.gruplar.value.forEach((el) => {
        expo.form.value.grup_fiyat.push({
          grup_k_no: el.k_no,
          fiyat: 0,
          kur: "USD",
        });
      });
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      const baseUrl =
        expo.form.value.k_no == null ? "harcFiyatEkle" : "harcFiyatGuncelle";
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(
              !expo.form.value.k_no ? "Ekleme Başarılı" : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );

            context.emit("show", false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          console.log(Object.keys(val));
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-sepet-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  margin-bottom: 30px;
  // p-4 d-flex align-items-center justify-content-between border border-bottom
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.sidebar-sepet-items {
  position: relative;
  padding-top: 50px;
}
.sidebar-sepet-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  z-index: 10;
}

.timeline {
  border-left: 3px solid #e1e1e1;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(174, 174, 174, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 10px 0px 10px 25px;
  list-style: none;
  text-align: left;
  max-width: 90%;
  @media (max-width: 767px) {
    max-width: 98%;
    padding: 25px;
  }
  h1 {
    font-weight: 300;
    font-size: 1.4em;
  }
  h3 {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  h4 {
    font-weight: 400;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  .event {
    border-bottom: 3px dashed #0a7ced;
    padding-bottom: 10px;
    margin-bottom: 25px;
    position: relative;
    @media (max-width: 767px) {
      padding-top: 30px;
    }
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }
    &:after,
    :before {
      position: absolute;
      display: block;
      top: 0;
    }
    &:before {
      left: -207px;
      // content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 0.9em;
      min-width: 120px;
      @media (max-width: 767px) {
        left: 0px;
        text-align: left;
      }
    }
    &:after {
      -webkit-box-shadow: 0 0 0 3px #0a7ced;
      box-shadow: 0 0 0 3px #0a7ced;
      left: -37px;
      background: #fff;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      content: "";
      top: 20px;
      @media (max-width: 767px) {
        left: -31.8px;
      }
    }
  }
}
</style>
