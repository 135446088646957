<template>
  <div class="p-2">
    <!-- {{ rows }} -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: false,
        perPage: 100,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'grup'">
          <span class="d-block text-info">
            {{ props.row.grup_adi }}
          </span>
        </span>
        <span
          v-else-if="props.column.field === 'action'"
          class="float-right px-1"
        >
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="warning"
              class="rounded-0"
              @click="handlerUpdate(props.row)"
            >
              <i class="fad fa-edit" />
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="danger"
              class="rounded-0"
              @click="handlerRemove(props.row.k_no)"
            >
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom"> </template>
    </vue-good-table>
    <FiyatTanimla
      :openModal="openModal"
      @closeModal="handlerCloseModal($event)"
      @show="show = $event"
      :updateData="updateData"
      :kurlar="kurlar"
    />
  </div>
</template>
<script>
import store from "@/store";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import { useToast } from "vue-toastification/composition";
import { defineComponent, ref, computed, toRefs } from "@vue/composition-api";

import FiyatTanimla from "./FiyatTanimla.vue";
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    FiyatTanimla,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    const { openModal } = toRefs(props);

    expo.kurlar = ref([]);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.perPageOptions = ref([20, 40, 50, 80, 100]);
    expo.count = ref(0);
    expo.filter = ref({
      arama: null,
      urun_k_no: null,
      siralama: "artan",
      page: 0,
      pageSize: 20,
    });

    expo.columns = ref([
      {
        label: "Günler",
        field: "gunler",
        width: "200px",
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit("show", true);
      await store.dispatch("harcGrupListele");
      await store.dispatch("kurTurleriListele");
      const grup = store.getters.getHarcGrup;
      expo.kurlar.value = store.getters.getKurTurleri;
      grup.forEach((el) => {
        expo.columns.value.push({
          label: el.baslik,
          field: el.k_no,
          width: "110px",
          tdClass: "text-right",
          thClass: "text-right",
        });
      });

      expo.columns.value.push({
        label: "İşlem",
        field: "action",
        width: "80px",
      });
      await store.dispatch("harcFiyatListele").then((res) => {
        if (res.data.success) {
          const data = store.getters.getHarcFiyat;
          data.forEach((el) => {
            const formattedData = {};

            el.grup_fiyat.forEach((item) => {
              formattedData[item.grup_k_no] = `${parseFloat(
                item.fiyat.replace(",", ".")
              ).toFixed(2)} ${item.kur}`;
            });

            expo.rows.value.push({
              ...el,
              gunler: `${el.baslik} ( ${el.gun} Gün )`,
              ...formattedData,
            });
          });
        }
      });
      context.emit("show", false);
    };
    handlerFetchAllData();

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      handlerFetchAllData();
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: "Uyarı",
        text: "Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch("harcUlkeSil", k_no).then((res) => {
            if (res.data.success === true) {
              toast.error("Silme Başarılı", { position: "bottom-left" });
            }
          });
        }
      });
    };

    expo.handlerAdd = () => {
      expo.updateData.value = {};
      openModal.value = true;
    };

    expo.handlerUpdate = (payload) => {
      expo.updateData.value = payload;
      context.emit("closeModal", true);
    };

    expo.handlerCloseModal = (event) => {
      if (!event) {
        expo.updateData.value = {};
        context.emit("closeModal", event);
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
