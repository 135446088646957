<template>
  <div class="p-2">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="false"
      :pagination-options="{
        enabled: false,
        perPage: 1000,
      }"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
      }"
      styleClass="vgt-table condensed striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'harc'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.harc"
            switch
            @change="handlerStatu('harc', props.row.k_no, $event)"
            size="lg"
            v-b-tooltip.hover.top="'Harçtan Muaf mi ?'"
          />
        </span>
        <span v-else-if="props.column.field === 'vize'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.vize"
            switch
            @change="handlerStatu('vize', props.row.k_no, $event)"
            size="lg"
            v-b-tooltip.hover.top="'Tek Giriş Vizeden Muaf mi ?'"
          />
        </span>
        <span v-else-if="props.column.field === 'kart'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.kart"
            switch
            @change="handlerStatu('kart', props.row.k_no, $event)"
            size="lg"
            v-b-tooltip.hover.top="'Kart Defter bedelinden Muaf mi ?'"
          />
        </span>
        <span v-else-if="props.column.field === 'evlilik'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.evlilik"
            switch
            @change="handlerStatu('evlilik', props.row.k_no, $event)"
            size="lg"
            v-b-tooltip.hover.top="
              'TR. İle Evlilik Durumu da Harçtan Muaf mi ?'
            "
          />
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.statu"
            switch
            @change="handlerStatu('statu', props.row.k_no, $event)"
            size="lg"
            v-b-tooltip.hover.top="'Kullanım Durumu'"
          />
        </span>
        <span
          v-else-if="props.column.field === 'action'"
          class="float-right px-1"
        >
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="warning"
              class="rounded-0"
              @click="handlerUpdate(props.row)"
            >
              <i class="fad fa-edit" />
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="danger"
              class="rounded-0"
              @click="handlerRemove(props.row.k_no)"
            >
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center my-3">
            <v-select
              v-model="filter.pageSize"
              :options="perPageOptions"
              :clearable="false"
              style="width: 200px"
              class="invoice-filter-select"
              @input="(value) => handlerPageSizeChange(value)"
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="count"
              :per-page="filter.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="my-3"
              @input="(value) => handlerPageChange(value)"
            >
              <template #prev-text>
                <i class="fad fa-chevron-left" />
              </template>
              <template #next-text>
                <i class="fad fa-chevron-right" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <UlkeTanimla
      :openModal="openModal"
      @closeModal="handlerCloseModal($event)"
      @show="show = $event"
      :updateData="updateData"
    />
  </div>
</template>
<script>
import store from "@/store";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import { useToast } from "vue-toastification/composition";
import BTableCard from "@/components/cards/BTableCard.vue";
import { defineComponent, ref, computed, toRefs } from "@vue/composition-api";
import UlkeTanimla from "./UlkeTanimla.vue";
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
    UlkeTanimla,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    const { openModal } = toRefs(props);

    expo.show = ref(false);
    expo.updateData = ref({});
    expo.perPageOptions = ref([20, 40, 50, 80, 100]);
    expo.count = ref(0);
    expo.filter = ref({
      arama: null,
      urun_k_no: null,
      siralama: "artan",
      page: 0,
      pageSize: 20,
    });
    expo.columns = ref([
      {
        label: "Grup",
        field: "grup_adi",
        width: "12%",
      },
      {
        label: "Ülke",
        field: "baslik",
      },
      {
        label: "Harc",
        field: "harc",
        width: "9%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Vize",
        field: "vize",
        width: "9%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Kart ( D.B )",
        field: "kart",
        width: "9%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Evlilik",
        field: "evlilik",
        width: "9%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Statu",
        field: "statu",
        width: "9%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "İşlemler",
        field: "action",
        width: "9%",
        thClass: "text-right",
        tdClass: "text-right",
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit("show", true);
      await store.dispatch("harcGrupListele");
      await store.dispatch("harcUlkeListele", expo.filter.value).then((res) => {
        if (res.data.success) {
          expo.rows.value = store.getters.getHarcUlke;
          expo.count.value = res.data.count;
          context.emit("total", expo.count.value);
        }
      });
      context.emit("show", false);
    };
    handlerFetchAllData();

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      handlerFetchAllData();
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: "Uyarı",
        text: "Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch("harcUlkeSil", k_no).then((res) => {
            if (res.data.success === true) {
              toast.error("Silme Başarılı", { position: "bottom-left" });
            }
          });
        }
      });
    };

    expo.handlerStatu = (type, k_no, event) => {
      store
        .dispatch("harcUlkeStatuGuncelle", { type, k_no, event })
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Guncelle Başarılı", { position: "bottom-left" });
          }
        });
    };

    expo.handlerAdd = () => {
      expo.updateData.value = {};
      openModal.value = true;
    };

    expo.handlerUpdate = (payload) => {
      expo.updateData.value = payload;
      context.emit("closeModal", true);
    };

    expo.handlerCloseModal = (event) => {
      if (!event) {
        expo.updateData.value = {};
        context.emit("closeModal", event);
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
