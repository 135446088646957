<template>
  <div class="h-100 w-100">
    <BListCard
      :searchActive="false"
      :newAddActive="false"
      :totalActive="false"
      :show="show"
      :title="`${selectMenuItem}`"
      :total="0"
      leftStyle="width:300px"
    >
      <template v-slot:leftBody>
        <ul class="menu-list-group">
          <li
            class="menu-list-group-item"
            v-for="(item, index) in menus"
            :key="index"
          >
            <router-link :to="{ name: item.route }">
              <i v-if="item.icon" :class="`fad fa-${item.icon}`" />
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </template>
      <template v-slot:rightAction>
        <b-button
          v-if="
            selectMenuItem == 'Ülke Tanımı' || selectMenuItem == 'Fiyatlandırma'
          "
          squared
          variant="info"
          class="ml-4"
          @click="openUlkeModal = true"
        >
          <i class="fad fa-plus" /> Yeni Kayıt
        </b-button>
      </template>
      <template v-slot:rightBody>
        <div class="h-100">
          <transition :name="transitionEffect" mode="out-in">
            <router-view
              @show="show = $event"
              :openModal="openUlkeModal"
              @closeModal="openUlkeModal = $event"
            />
          </transition>
        </div>
      </template>
    </BListCard>
  </div>
</template>

<script>
import BListCard from "@/components/cards/BListCard.vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useRouter } from "@/libs/utils";
import store from "@/store";
export default defineComponent({
  components: {
    BListCard,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.show = ref(false);
    expo.menus = ref([
      {
        title: "Fiyatlandırma",
        route: "harc-fiyatlandirma",
        icon: "bars",
      },
      {
        title: "Grup Tanımı",
        route: "harc-grup",
        icon: "bars",
      },
      {
        title: "Ülke Tanımı",
        route: "harc-ulke",
        icon: "bars",
      },
      {
        title: "Ayarlar",
        route: "harc-ayarlar",
        icon: "bars",
      },
    ]);

    expo.openUlkeModal = ref(false);

    expo.transitionEffect = computed(() => store.getters.getTransitionEffect);
    expo.selectMenuItem = ref(
      expo.menus.value.find((x) => x.route == route.value.name).title
    );

    watch(route, (val) => {
      expo.selectMenuItem.value = expo.menus.value.find(
        (x) => x.route == val.name
      ).title;
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.menu-list-group {
  padding: 10px;
  border-radius: 0;
  .menu-list-group-item {
    position: relative;
    border: none;
    padding: 0px;
    margin: 5px;
    display: block;
    justify-content: space-between;
    align-items: center;
    a {
      color: #4d4d4d;
      font-size: 18px;
      font-weight: 500;
      display: block;
      padding: 15px 10px;
      transition: all 0.2s ease-in-out;
      i {
        padding-right: 10px;
      }
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
      &.router-link-active {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
