<template>
  <tr>
    <td>
      <v-select
        v-model="item.grup_k_no"
        :options="gruplar"
        :reduce="(uye) => uye.k_no"
        label="baslik"
        :clearable="false"
        class="invoice-filter-select select-size-lg d-block"
        style="width: 250px"
      >
        <template slot="selected-option" slot-scope="option">
          {{ option.baslik }}
        </template>
        <template slot="option" slot-scope="option">
          {{ option.baslik }}
        </template>
      </v-select>
    </td>
    <td>
      <validation-provider
        name="Başlık"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-input
          size="lg"
          class="rounded-0"
          ref="baslik"
          v-model="item.fiyat"
          :state="getValidationState(validationContext)"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-invalid-feedback id="input-1-live-feedback">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </td>
    <td>
      <v-select
        v-model="item.kur"
        :options="kurlar"
        :reduce="(uye) => uye.banka_kodu"
        value="banka_kodu"
        :clearable="false"
        class="invoice-filter-select select-size-lg d-block"
        style="width: 150px"
      >
        <template slot="selected-option" slot-scope="option">
          {{ option.banka_kodu }}
        </template>
        <template slot="option" slot-scope="option">
          {{ option.banka_kodu }}
        </template>
      </v-select>
    </td>
  </tr>
</template>

<script>
import store from "@/store";
import { ref, computed, defineComponent } from "@vue/composition-api";
import vSelect from "vue-select";

export default defineComponent({
  components: { vSelect },
  props: {
    item: { type: Object, required: true },
    kurlar: { type: Array, required: true },
  },
  setup() {
    const expo = {};
    expo.gruplar = computed(() => store.getters.getHarcGrup);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
